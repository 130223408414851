import { whatsappLink } from '@global/utils/url/create-whatsapp-url';
import { SEO } from '@lp-root/src/components/legacy/mol.seo/seo.component';
import { EmailContacts } from '@lp-root/src/domain/contacts';
import OrderSuccess from '@lp-root/src/modules/order-success/order-success.component';
import { PageProps } from '@lp-src/utils/local-types';
import { Body, H3 } from '@web/atomic';
import WhatsappFAB from '@web/atomic/atm.whatsapp-fab/whatsapp-fab.component';
import { getCanonicalUrl } from '@web/utils/url';
import { graphql } from 'gatsby';
import * as React from 'react';

/** @deprecated 6Qxlq3Py43BbkD5G telehealth was deprecated in june 2023 - https://www.notion.so/vigilantesdosono/remo-o-teleconsultas-pt1-23098acaf8de4bd085668cbbd92bf249?pvs=4 */
const CompraRealizadaPage: React.FunctionComponent<PageProps> = (props) => {
  const socialMedia = {
    canonicalUrl: getCanonicalUrl(props.data.site.siteMetadata.siteUrl, props.location.pathname),
    title: 'Sucesso! Estamos processando seu pedido!',
    description: 'Assim que o pagamento for identificado, as consultas estarão disponíveis para agendamento para você.',
  };
  return (
    <>
      <SEO socialMedia={socialMedia} />
      <OrderSuccess headline={'Sucesso!'} hideDownload={true}>
        <H3>
          Sucesso{' '}
          <span role="img" aria-label="Festa">
            {' '}
            🎉{' '}
          </span>{' '}
          ! Estamos processando seu pedido!
        </H3>
        <Body>
          Assim que o pagamento for identificado no nosso sistema, iremos enviar um e-mail com a liberação das consultas para você!
        </Body>
        <Body>
          Para quaisquer dúvidas ou dificuldades, você pode entrar em contato com nosso suporte no número de{' '}
          <a href={whatsappLink('sales')} rel="noreferrer" target="_blank">
            {' '}
            WhatsApp: +55 (11) 91157-9831{' '}
          </a>{' '}
          ou no e-mail: <a href={`mailto:${EmailContacts.Support}`}>{EmailContacts.Support}</a>.
        </Body>
      </OrderSuccess>
      <WhatsappFAB kind="support" />
    </>
  );
};

export default CompraRealizadaPage;

export const query = graphql`
  query CompraRealizadaQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`;
